// src/layouts/FrontPageLayout.js
import React from 'react';
import FrontPageHeader from '../components/frontpage/Header';  // Import your frontpage header
import FrontPageFooter from '../components/frontpage/Footer';  // Import your frontpage footer

function FrontPageLayout({ children }) {
  return (
    <div>
      <FrontPageHeader />
      {children}  {/* This will render the main content of the frontpage */}
      <FrontPageFooter />
    </div>
  );
}

export default FrontPageLayout;
