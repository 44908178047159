// src/layouts/EshopLayout.js
import React from 'react';
import EshopHeader from '../components/eshop/Header';  // Import your eshop header
import EshopFooter from '../components/eshop/Footer';  // Import your eshop footer

function EshopLayout({ children }) {
  return (
    <div>
      <EshopHeader />
      {children}  {/* This will render the main content of the eshop */}
      <EshopFooter />
    </div>
  );
}

export default EshopLayout;
