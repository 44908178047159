import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function AddToCartModal({ showAlert, setShowAlert }) {
  const [show, setShow] = useState(showAlert);

  useEffect(() => {
    setShow(showAlert);
    const timer = setTimeout(() => {
      setShow(false);
      setShowAlert(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [showAlert, setShowAlert]);

  const handleClose = () => {
    setShow(false);
    setShowAlert(false);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Item Added to Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>Item added to cart successfully!</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddToCartModal;
