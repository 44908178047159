import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Searchbar from './Searchbar';
import ShoppingCartModal from './ShoppingCartModal';
import './Header.css';
import axios from 'axios';
import { getCategoryDisplayName } from '../../utils/eshop/categoryNameUtil';


function Header() {

  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.svkrobotics.com/api/items/get/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const viewCart = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <>
    <Navbar sticky='top' className='header-navbar'>
      <Col>

        <Container className='header-cont'>
          <Row>

            <Col>
              <a href='/eshop'>
                <img src='/logos/logo SVK.png' className='header-logo' alt='Logo' />
              </a>
            </Col>

            <Col className="d-flex justify-content-center" xs={6}> {/* This centers the search bar in the header */}
              <Searchbar />
            </Col>

            {/* <Col>
              <img src='/icons/profile-circle.svg' className='login-icon' />
              <Link to={'/eshop'} style={{ textDecoration: "none" , color: "inherit"}}>
                <h5>Login/Register</h5>
              </Link>
            </Col> */}

            <Col className="d-flex justify-content-center align-items-center">
                <div className='shopping-cart-col' onClick={viewCart}>
                    <img src='/icons/shopping-cart-01.svg' className='shopping-cart-icon' alt="Shopping Cart" />
                    <h5>View Cart</h5>
                </div>
            </Col>

            
          </Row>
        </Container>
        
        <Container className='nav-container'>
          <Nav>
          <Col md={2}></Col>
          <Col md={2}>
            <NavDropdown className='nav-dropdown' title="Categories">
            {categories.map((category, index) => {
              console.log(category);
              return (
                <Link to={`/eshop/category/${category}`} key={index} className="dropdown-item">{getCategoryDisplayName(category)}</Link>
              );
            })}
            </NavDropdown>
            </Col>
            {/* Space between categories and nav links */}
            {/* <Col md={1}></Col> */}

            <div className='nav-links'>
                <Link to="/eshop">Home</Link>
                <Link to="/eshop/category/robot-vehicles">Robot Vehicles</Link>
                <Link to="/eshop/category/robot-parts">Robot Parts</Link>
                <Link to="/eshop/category/educational-kits">Educational Kits</Link>
              </div>
          </Nav>
        </Container>

      </Col>
    </Navbar>

    <ShoppingCartModal show={showModal} onHide={handleClose} />
    </>
  );
}

export default Header;
