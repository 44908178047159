import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Contact.css'; // Import the CSS file for styling

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send form data to an API or display a message
    console.log(formData);
  };

  return (
    <div className="contact-page">
      <Container>
        <h1>Contact Us</h1>
        <Row>
          <Col md={6}>
            <h2>Get in Touch</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  placeholder="Enter your message"
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Col>

          <Col md={6} className="contact-info">
            <h2>Contact Information</h2>
            <p><strong>Email:</strong> info@svkrobotics.com</p>
            <p><strong>Phone:</strong> +30 281 600 8699</p>
            <p><strong>Or:</strong> +30 694 758 6765</p>
            <h2>Our Location</h2>
            <p>Greece</p>
            <p>Heraklion, Crete</p>
            <p>Radamanthous 18, 71201</p>

            {/* Embed Google Maps iframe */}
            <iframe
              title="SVK Robotics Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.74129809221!2d25.126719076505765!3d35.337245172700165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149a5b0ce30e43e3%3A0x20b36646c92ba26b!2sSVK%20ROBOTICS!5e0!3m2!1sen!2sgr!4v1728863221029!5m2!1sen!2sgr"
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
