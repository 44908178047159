// src/pages/TermsOfUse.js
import React from 'react';
import { Container } from 'react-bootstrap';

function TermsOfUse() {
  return (
    <div className="terms-of-use-page">
      <Container>
        <h1>Terms of Use</h1>
        <p>Last updated: October 15, 2024</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to SVK Robotics ("Company", "we", "our", "us"). By accessing and using our website, 
          including any related subdomains or services offered through the website (collectively, the "Site"), 
          you agree to be bound by these Terms of Use ("Terms"). If you do not agree with any of these Terms, 
          you should not use the Site.
        </p>
        <p>
          These Terms apply to all visitors, users, and others who access the Site or make purchases through 
          our e-shop.
        </p>

        <h2>2. Eligibility</h2>
        <p>
          You must be at least 18 years of age to access and use our Site and services. By accessing or using the Site, 
          you represent and warrant that you have the legal capacity to enter into a binding agreement with us and that 
          you are not barred from using the Site under any applicable law.
        </p>

        <h2>3. Intellectual Property</h2>
        <p>
          All content, materials, logos, designs, text, images, graphics, videos, and other intellectual property on the Site 
          are the exclusive property of SVK Robotics or its licensors. You may not use, reproduce, distribute, or modify any 
          content from our Site without express permission from SVK Robotics, except as permitted under applicable law.
        </p>

        <h2>4. Use of the Site</h2>
        <p>
          You agree to use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site:
        </p>
        <ul>
          <li>In any way that violates any applicable local, national, or international law or regulation.</li>
          <li>To engage in any fraudulent, misleading, or malicious activities.</li>
          <li>To upload or transmit any harmful code, viruses, or any content that may damage or disrupt the Site or its services.</li>
          <li>To infringe on the intellectual property rights or privacy of others.</li>
        </ul>

        <h2>5. E-Shop Terms</h2>
        <h3>5.1 Product Information</h3>
        <p>
          We make every effort to display accurate information, including product descriptions, prices, and availability. However, 
          SVK Robotics cannot guarantee that all content is error-free, complete, or up-to-date. We reserve the right to correct 
          any errors, inaccuracies, or omissions at any time, including after an order has been placed.
        </p>

        <h3>5.2 Orders and Payments</h3>
        <p>
          By placing an order on our e-shop, you agree to provide accurate, complete, and current information. We reserve the right 
          to refuse or cancel any order at our discretion, including cases where the product is unavailable, there is a pricing 
          error, or we suspect fraud. In such cases, you will be notified, and any payment already made will be refunded.
        </p>
        <p>
          Payments are processed securely through third-party payment gateways. You agree to pay all applicable charges for your 
          purchase, including taxes and shipping fees.
        </p>

        <h3>5.3 Shipping and Delivery</h3>
        <p>
          Shipping times and costs vary depending on your location and the items purchased. We will provide estimated delivery dates, 
          but these are subject to change due to factors beyond our control. We are not liable for any delays in shipping or delivery.
        </p>

        <h3>5.4 Returns and Refunds</h3>
        <p>
          We accept returns of products purchased through our e-shop in accordance with our Return Policy. Refunds will be processed to the original payment 
          method within a reasonable time after receiving the returned item.
        </p>

        <h2>6. GDPR and Data Protection</h2>
        <p>
          SVK Robotics is committed to protecting your privacy and complying with the General Data Protection Regulation (GDPR). 
          By using our Site or purchasing products from our e-shop, you consent to the collection, use, and storage of your personal 
          data as outlined in our <a href="/privacy-policy">Privacy Policy</a>.
        </p>

        <h3>6.1 Your Rights Under GDPR</h3>
        <p>
          Under GDPR, you have the right to:
        </p>
        <ul>
          <li>Access your personal data that we store.</li>
          <li>Request correction or deletion of your personal data.</li>
          <li>Restrict or object to the processing of your personal data.</li>
          <li>Withdraw consent at any time, where consent is the legal basis for processing.</li>
        </ul>
        <p>
          For any requests or inquiries regarding your personal data, please contact us at <a href="mailto:info@svkrobotics.com">info@svkrobotics.com</a>.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, SVK Robotics and its affiliates shall not be liable for any direct, indirect, 
          incidental, consequential, or punitive damages, including loss of profits, data, or use, arising from your access to 
          or use of our Site or services. This limitation of liability applies whether the claim is based on contract, tort, 
          negligence, strict liability, or any other legal theory.
        </p>

        <h2>8. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless SVK Robotics, its affiliates, officers, directors, employees, and 
          agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable legal fees, 
          arising out of or in any way related to your use of the Site, your violation of these Terms, or your infringement of any 
          third-party rights.
        </p>

        <h2>9. Changes to the Terms</h2>
        <p>
          SVK Robotics reserves the right to update or modify these Terms at any time without prior notice. Any changes will be 
          effective immediately upon posting on the Site. It is your responsibility to review these Terms periodically. Continued 
          use of the Site after changes are posted constitutes acceptance of the revised Terms.
        </p>

        <h2>10. Governing Law</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws of Greece and the European Union. Any disputes 
          arising from these Terms or your use of the Site will be subject to the exclusive jurisdiction of the courts of Greece.
        </p>

        <h2>11. Contact Information</h2>
        <p>
          If you have any questions about these Terms of Use, please contact us at <a href="mailto:info@svkrobotics.com">info@svkrobotics.com</a>.
        </p>
      </Container>
    </div>
  );
}

export default TermsOfUse;
