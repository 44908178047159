// src/pages/EshopRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ShopGrid from '../pages/eshop/ShopGrid';
import Landing from '../pages/eshop/Landing';
import ShoppingCart from '../pages/eshop/ShoppingCart';
import Item from '../pages/eshop/Item';
import Checkout from '../pages/eshop/Checkout';
import Confirmation from '../pages/eshop/Confirmation';


function EshopRoutes() {
  return (

    <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/category/:categoryUrl' element={<ShopGrid />} />
        <Route path='/item/:itemId/:slug?' element={<Item />} /> {/* Modified this line */}
        <Route path='/cart' element={<ShoppingCart />} />
        <Route path='/checkout/:orderUUID' element={<Checkout />} />
        <Route path='/confirmation/:orderUUID' element={<Confirmation />} />
        <Route path='*' element={<Landing />} />
    </Routes>

  );
}

export default EshopRoutes;
