import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Services.css';

function Services() {
  return (
    <Container fluid className="services-page">
      {/* First Service */}
      <Row className="service-row">
        <Col lg={12} className="service-image-col">
          <div className="service-image-container" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/photos/Frontpage/svk-frontpage-robotics.webp')` }}>
            <div className="service-text-overlay">
              <h2>Robotic/Mechatronic Solutions</h2>
              <p>
                We specialize in providing customizable robotic and mechatronic solutions designed to automate and enhance efficiency across industries. From manufacturing and logistics to healthcare, our robotic systems—including robotic arms, AGVs, and cobots—are built to handle tasks with precision and consistency. These solutions offer significant improvements in productivity and safety, whether used in industrial applications or in sectors like agriculture and healthcare, where automated systems reduce human error and optimize performance.
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <div className="service-spacing"></div>

      {/* Second Service */}
      <Row className="service-row">
        <Col lg={12} className="service-image-col">
          <div className="service-image-container" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/photos/Frontpage/svk-frontpage-edu.jpeg')` }}>
            <div className="service-text-overlay">
              <h2>Educational Robotic Kits</h2>
              <p>
                Our educational robotic kits are designed to inspire learners of all ages in STEM fields. Easy to use and packed with advanced features, these kits help students and hobbyists learn the fundamentals of robotics, electronics, and programming. Perfect for both beginners and advanced users, our kits offer hands-on experience in building and coding robots that can perform complex tasks, nurturing problem-solving and critical thinking skills.
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <div className="service-spacing"></div>

      {/* Third Service */}
      <Row className="service-row">
        <Col lg={12} className="service-image-col">
          <div className="service-image-container" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/photos/Frontpage/svk-frontpage-pcb-assembly.webp')` }}>
            <div className="service-text-overlay">
              <h2>PCB Design/Assembly</h2>
              <p>
                We provide expert PCB design and assembly services, offering precise and reliable circuit boards for a variety of applications. From initial concept through to final assembly, our team ensures that each PCB is optimized for performance and manufacturability. We serve industries such as consumer electronics, automotive, and medical devices, utilizing both SMT and through-hole technologies to bring designs to life efficiently.
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <div className="service-spacing"></div>

      {/* Fourth Service - Eshop */}
      <Row className="service-row">
        <Col lg={12} className="service-image-col">
          <div className="service-image-container" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/photos/Frontpage/svk-frontpage-eshop.webp')` }}>
            <div className="service-text-overlay">
              <h2>Eshop</h2>
              <p>
                Our Eshop offers a vast range of robotics components, kits, and tools for hobbyists, engineers, and educators. Whether you're building a custom robot, working on a DIY electronics project, or need specific parts, we have everything you need. With high-quality products and fast shipping, our Eshop is the go-to source for all your robotics needs.
              </p>
              <Button as="a" href="/eshop" variant="primary" className="service-button">
                Shop Now
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="service-spacing"></div>
    </Container>
  );
}

export default Services;
