// src/pages/About.js
import React from 'react';
import { Container } from 'react-bootstrap';

function About() {
  return (
    <div className="about-us-page">
      <Container>
        <h1>About SVK Robotics</h1>
        <p>
          SVK Robotics is a leading robotics and mechatronics solutions company, 
          providing innovative solutions for various industries. Our expertise extends to 
          custom robotic vehicles, educational robotics kits, and PCB design and assembly.
        </p>
        <p>
          Our mission is to empower industries and educational institutions by delivering cutting-edge robotic 
          systems and mechatronic solutions that meet specific needs.
        </p>
        {/* Add more detailed content about the company's mission, team, etc. */}
      </Container>
    </div>
  );
}

export default About;
