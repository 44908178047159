import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ItemCard from './ItemCard';
import Breadcrumbs from './Breadcrumbs';
import axios from 'axios';
import './ItemGrid.css';

const calculateCols = (screenWidth) => {
  if (screenWidth >= 1200) {
    return 3; // Large screens (lg)
  } else if (screenWidth >= 992) {
    return 3; // Medium screens (md)
  } else if (screenWidth >= 768) {
    return 2; // Small screens (sm)
  } else {
    return 1; // Extra small screens (xs)
  }
};

function ItemGrid({ category }) {
  const [data, setData] = useState([]);
  const screenWidth = window.innerWidth;
  const cols = calculateCols(screenWidth);

  useEffect(() => {
    const fetchData = async () => {
      let url;
      console.log('Current Category:', category); // Log current category

      // Ensure category is valid
      const validCategories = [
        'robot-vehicles',
        'robot-parts',
        'educational-kits',
        'accessories',
        'all-items', // Include all-items here
        'highlight'
      ];

      if (!validCategories.includes(category)) {
        console.error('Invalid category passed:', category);
        return; // Exit if category is invalid
      }

      // Construct the URL based on the category
      if (category === "highlight") {
        url = 'https://api.svkrobotics.com/api/items/get/highlighted';
      } else if (category === "all-items") {
        url = 'https://api.svkrobotics.com/api/items/get/all';
      } else {
        url = `https://api.svkrobotics.com/api/items/category/${category}`;
      }

      try {
        const response = await axios.get(url);
        console.log('API Response:', response.data); // Log the full response data
        setData(response.data.items || response.data || []); // Adjust based on response structure
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (category) {
      fetchData();
    }
  }, [category]);

  return (
    <Container className='grid-cont'>
      <Breadcrumbs type="category" data={data} />
      {data.length > 0 ? (
        <Row>
          {data.map((item) => (
            <Col className='item-grid-col' key={item.id} xs={12} sm={12 / cols} md={12 / cols} lg={12 / cols}>
              <ItemCard item={item} />
            </Col>
          ))}
        </Row>
      ) : (
        <div>No items available for this category.</div>
      )}
    </Container>
  );
}

export default ItemGrid;
