import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { getCategoryDisplayName } from '../../utils/eshop/categoryNameUtil';

const Breadcrumbs = ({ type, data }) => {
  const location = useLocation();

  if (type === "item") {
    return (
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/eshop/category/${data?.category}` }}>
          {getCategoryDisplayName(data?.category)}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.name}</Breadcrumb.Item>
      </Breadcrumb>
    );
  } else if (type === "category") {
    const urlEndsWithAllItems = location.pathname.endsWith("all-items");
    const urlIsRoot = location.pathname === "/";
    return (
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>
          {urlEndsWithAllItems ? "All Items" : (urlIsRoot ? "Popular" : (data?.items?.length > 0 ? getCategoryDisplayName(data.items[0].category) : 'Category Name'))}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  } else {
    return null; // Render nothing if type is neither "item" nor "category"
  }
};

export default Breadcrumbs;
