// src/pages/PrivacyPolicy.js
import React from 'react';
import { Container } from 'react-bootstrap';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-page">
      <Container>
        <h1>Privacy Policy</h1>
        <p>Last updated: October 15, 2024</p>

        <p>
          SVK Robotics ("we", "our", "us") respects your privacy and is committed to protecting your personal data. 
          This privacy policy will inform you about how we handle your personal information, your privacy rights, 
          and how the law protects you, particularly in accordance with the General Data Protection Regulation (GDPR).
        </p>

        <h2>1. Important Information and Who We Are</h2>
        <p>
          SVK Robotics is a company specializing in robotics and mechatronics solutions, as well as selling robotics products 
          through our e-shop. This privacy policy applies to the use of our website and e-shop, and it explains how we collect, 
          store, and process your personal data.
        </p>

        <h3>Contact Information</h3>
        <p>
          If you have any questions about this privacy policy or how we handle your personal data, please contact us at:
        </p>
        <p>Email: <a href="mailto:info@svkrobotics.com">info@svkrobotics.com</a></p>
        <p>Address: Ραδαμάνθους 18, Heraklion, Crete, Greece</p>

        <h2>2. What Data We Collect</h2>
        <p>We may collect, use, store, and transfer different kinds of personal data about you, including but not limited to:</p>
        <ul>
          <li><strong>Identity Data:</strong> First name, last name, username or similar identifier, title, and gender.</li>
          <li><strong>Contact Data:</strong> Billing address, delivery address, email address, and telephone numbers.</li>
          <li><strong>Financial Data:</strong> Payment card details, though processed securely by third-party payment gateways.</li>
          <li><strong>Transaction Data:</strong> Details about payments and purchases you have made from our e-shop.</li>
          <li><strong>Technical Data:</strong> IP address, browser type and version, time zone settings, and other technology on the devices you use to access the Site.</li>
          <li><strong>Profile Data:</strong> Purchases or orders made by you, preferences, and feedback.</li>
          <li><strong>Usage Data:</strong> Information about how you use our website, products, and services.</li>
          <li><strong>Marketing and Communications Data:</strong> Preferences in receiving marketing from us and your communication preferences.</li>
        </ul>

        <h2>3. How We Collect Your Personal Data</h2>
        <p>We use different methods to collect data from and about you, including:</p>
        <ul>
          <li>
            <strong>Direct Interactions:</strong> You may provide data by filling in forms on our site or by corresponding with us 
            by post, phone, email, or otherwise. This includes data you provide when you purchase products, create an account, subscribe to our newsletter, or give feedback.
          </li>
          <li>
            <strong>Automated Technologies or Interactions:</strong> As you interact with our website, we may automatically collect 
            technical data about your browsing actions and patterns. We collect this personal data using cookies, server logs, and 
            other similar technologies.
          </li>
          <li>
            <strong>Third Parties or Publicly Available Sources:</strong> We may receive personal data about you from various third 
            parties, including analytics providers, advertising networks, and search information providers.
          </li>
        </ul>

        <h2>4. How We Use Your Personal Data</h2>
        <p>We will only use your personal data when the law allows us to. Most commonly, we will use your data in the following circumstances:</p>
        <ul>
          <li>Where we need to perform the contract we are about to enter into or have entered into with you (for example, when processing your orders).</li>
          <li>Where it is necessary for our legitimate interests (or those of a third party), and your interests and fundamental rights do not override those interests.</li>
          <li>Where we need to comply with a legal obligation.</li>
        </ul>

        <h3>Marketing and Promotional Offers</h3>
        <p>
          We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. You 
          will receive marketing communications from us if you have requested information from us, purchased goods from us, or if you 
          provided us with your details when you entered a competition or registered for a promotion.
        </p>

        <h2>5. Disclosures of Your Personal Data</h2>
        <p>We may share your personal data with third parties, including:</p>
        <ul>
          <li>Service providers who provide IT and system administration services.</li>
          <li>Professional advisers including lawyers, bankers, auditors, and insurers.</li>
          <li>Payment processors to facilitate payments through our e-shop.</li>
          <li>Third-party marketing platforms (subject to your preferences).</li>
          <li>Authorities, when required by law or to enforce our terms and policies.</li>
        </ul>

        <h2>6. International Transfers</h2>
        <p>
          Some of our external third-party providers may be based outside the European Economic Area (EEA), so their processing of your 
          personal data may involve a transfer of data outside the EEA. Whenever we transfer your personal data out of the EEA, we ensure 
          a similar degree of protection is afforded to it by ensuring specific contractual terms, known as Standard Contractual Clauses, 
          are in place.
        </p>

        <h2>7. Data Security</h2>
        <p>
          We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed 
          in an unauthorized way. In addition, we limit access to your personal data to those employees, agents, contractors, and other 
          third parties who have a business need to know. They will only process your personal data on our instructions and they are subject 
          to a duty of confidentiality.
        </p>
        <p>
          We have procedures in place to deal with any suspected data breach and will notify you and any applicable regulator of a breach 
          where we are legally required to do so.
        </p>

        <h2>8. Data Retention</h2>
        <p>
          We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the 
          purposes of satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the data, 
          the potential risk of harm from unauthorized use or disclosure, the purposes for which we process your personal data, and 
          whether we can achieve those purposes through other means.
        </p>

        <h2>9. Your Legal Rights</h2>
        <p>Under certain circumstances, you have the following rights under GDPR in relation to your personal data:</p>
        <ul>
          <li>The right to access the personal data we hold about you.</li>
          <li>The right to correct any inaccurate or incomplete data we hold about you.</li>
          <li>The right to request the deletion of your personal data where there is no good reason for us to continue processing it.</li>
          <li>The right to object to the processing of your personal data where we are relying on a legitimate interest.</li>
          <li>The right to restrict the processing of your personal data.</li>
          <li>The right to request the transfer of your personal data to you or a third party.</li>
          <li>The right to withdraw consent at any time where we are relying on consent to process your personal data.</li>
        </ul>
        <p>
          If you wish to exercise any of the rights set out above, please contact us at <a href="mailto:info@svkrobotics.com">info@svkrobotics.com</a>.
        </p>

        <h2>10. Cookies</h2>
        <p>
          Our website uses cookies to enhance user experience and analyze website traffic. A cookie is a small text file stored on your device. 
          We use both essential cookies for the functioning of the website and analytics cookies to gather statistical data.
        </p>
        <p>For more information about our use of cookies, please review our <a href="/cookie-policy">Cookie Policy</a>.</p>

        <h2>11. Changes to the Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. Any changes will be posted on this page, and where appropriate, we will 
          notify you by email or through other means. Please review this page periodically to ensure you are aware of any updates.
        </p>

        <h2>12. Contact Details</h2>
        <p>
          If you have any questions or concerns regarding this privacy policy or your personal data, please contact us at:
        </p>
        <p>Email: <a href="mailto:info@svkrobotics.com">info@svkrobotics.com</a></p>
        <p>Address: Ραδαμάνθους 18, Heraklion, Crete, Greece</p>

      </Container>
    </div>
  );
}

export default PrivacyPolicy;
