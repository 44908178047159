import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Newsletter from './Newsletter';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-div'>
      <Container>
        <Row className='md-3'>
          <Col md={4} className='footer-logo-cont'>
            <img src='/logos/logo SVK.png' className='footer-logo' alt='Logo-footer' />
          </Col>

          <Col md={4} className='socials-cont'>
            <p className='follow-us'>Follow us on</p>
            <Row className='social-icon-row'>
              <Col sm={2}>
                <a href='https://www.facebook.com/profile.php?id=61552752842212' target='_blank' rel='noopener noreferrer'>
                  <img className='social-icon' src='/icons/facebook.svg' alt='social-1' />
                </a>
              </Col>
              <Col sm={2}>
                <a href='https://www.instagram.com/svk.robotics/' target='_blank' rel='noopener noreferrer'>
                  <img className='social-icon' src='/icons/instagram.svg' alt='social-2' />
                </a>
              </Col>
              <Col sm={2}>
                <a href="https://gr.linkedin.com/company/svk-robotics" target="_blank" rel="noreferrer">
                  <img src="/icons/linkedin.svg" alt="LinkedIn" className="social-icon" />
                </a>
              </Col>
              <Col sm={2}>
                <a href='https://github.com/SVKROBOTICS' target='_blank' rel='noopener noreferrer'>
                  <img className='social-icon' src='/icons/github.svg' alt='social-4' />
                </a>
              </Col>
            </Row>
          </Col>

            {/* Google Maps Widget */}
            <Col md={4} className="d-flex justify-content-center align-items-center">
              <iframe
                title="SVK Robotics Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.74129809221!2d25.126719076505765!3d35.337245172700165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149a5b0ce30e43e3%3A0x20b36646c92ba26b!2sSVK%20ROBOTICS!5e0!3m2!1sen!2sgr!4v1728863221029!5m2!1sen!2sgr"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>


          {/* <Col md={3}>
            <Container className='newsletter-cont'><Newsletter /></Container>
          </Col> */}

        </Row>

        {/* Add the footer-divider */}
        <hr className="footer-divider" />

        {/* Footer-bottom section */}
        <div className="footer-bottom py-4">
          <Row>
            {/* Contact Us Section */}
            <Col className='contact-us' md={4}>
              <h5>Contact Us</h5>
              <p>Email: <a href='mailto:info@svkrobotics.com'>info@svkrobotics.com</a></p>
              <p>Phone: +30 281 600 8699</p>
              <p>Or: +30 694 758 6765</p>
            </Col>

            {/* Terms of Use / Privacy Policy */}
            <Col md={4} className="text-center">
              <a href="/terms-of-use" className="btn btn-link text-dark">Terms of Use</a>
              <a href="/privacy-policy" className="btn btn-link text-dark">Privacy Policy</a>
              <a href="/about" className="btn btn-link text-dark">About us</a>
            </Col>

            {/* Location Section */}
            <Col md={4}>
              <h5>Our Location</h5>
              <p>Greece</p>
              <p>Heraklion, Crete</p>
              <p>Radamanthous 18, 71201</p>
            </Col>
          </Row>
        </div>

        {/* Horizontal line before copyright */}
        <hr className="footer-divider" />

        {/* Copyright Notice */}
        <div className="text-center copyright py-3">
          <small>©2024 SVK Robotics. All rights reserved.</small>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
