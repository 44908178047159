import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Home.css'; // Import the CSS file
import FeatureGrid from '../../components/frontpage/FeatureGrid';

function Home() {
  return (
    <div className="home-page">
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/photos/Frontpage/svk-frontpage-landing.webp)`,
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1>Welcome to SVK Robotics</h1>
              <p>Innovative Robotic and Mechatronic Solutions, Educational Robotic Kits </p>
              <p>and PCB Design and Assembly </p>
              <div className="button-group">
                <Button variant="primary" href="/services" className="m-2">Explore Services</Button>
                <Button variant="outline-light" href="/projects" className="m-2">View Projects</Button>
                <Button variant="outline-light" href="/contact" className="m-2">Contact Us</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


      <div className="spacer-homepage"></div>

      {/* Information about SVK Robotics */}
      <div className="about-section">
        <Container>
          <Row>
            <Col>
              <h1>About SVK Robotics</h1>
              <p>
                SVK Robotics is a leading provider of innovative robotics and mechatronic solutions. 
                We specialize in designing and developing advanced robotic systems that cater to a wide range of industries. 
                Our team is dedicated to creating cutting-edge custom robotic solutions, from industrial automation to tailored projects.
                In addition to providing solutions for businesses, we offer educational robotics kits designed to inspire and educate the next generation of engineers and innovators.
                We also offer expertise in PCB (Printed Circuit Board) design and assembly, ensuring high-quality components for robotics and automation systems.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="small-spacer"></div>

      <FeatureGrid />

    </div>
  );
}

export default Home;
