import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Figure from 'react-bootstrap/Figure';
import countries from '../../data/countries.json';
import axios from 'axios';

import "./Checkout.css";

function Checkout() {
  const navigate = useNavigate();

  // Set default country and shipping option
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    zip: '',
    country: 'Greece', // Default country
    shippingOption: 'ACS', // Default shipping option for Greece
  });

  const [boxNowDetails, setBoxNowDetails] = useState({
    boxNowLockerPostalCode: '',
    boxNowLockerAddressLine1: '',
    boxNowLockerId: '',
  });

  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [isBoxNowWidgetInitialized, setIsBoxNowWidgetInitialized] = useState(false); // Flag to track widget initialization

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the country is being changed, adjust the shipping option accordingly
    if (name === 'country') {
      const newShippingOption = value === 'Greece' ? 'ACS' : 'FedEx';
      setFormData({
        ...formData,
        [name]: value,
        shippingOption: newShippingOption, // Update shipping option based on the selected country
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeliveryOptionChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      shippingOption: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    const requiredFields = ['firstName', 'lastName', 'email', 'country', 'shippingOption'];
    if (formData.shippingOption !== 'Box Now') {
      requiredFields.push('addressLine1', 'city', 'province', 'zip');
    }

    const isAllFieldsFilled = requiredFields.every(field => formData[field]);

    if (!isAllFieldsFilled) {
      // Display an alert if not all required fields are filled
      alert('Please fill in all required fields.');
      return;
    }

    // Merge Box Now details if "Box Now" is selected
    const finalData = formData.shippingOption === 'Box Now'
      ? { ...formData, ...boxNowDetails }
      : formData;

    try {
      const response = await axios.post('https://api.svkrobotics.com/api/orders/newOrder', finalData);
      setCreatedOrderId(response.data.orderId);
      // Navigate to confirmation page if form submission is successful
      navigate('/eshop/confirmation', { state: { orderId: response.data.orderId } });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Initialize the Box Now widget popup
  const initBoxNowWidget = () => {
    if (!isBoxNowWidgetInitialized) {
      if (!window._bn_map_widget_config) {
        // Initialize the Box Now widget config
        window._bn_map_widget_config = {
          partnerId: 123, // Replace with your actual partner ID
          parentElement: "#boxnowmap", // The ID of the div where the map will load
          buttonSelector: '.boxnow-map-widget-button', // The class of the button that triggers the widget
          afterSelect: function (selected) {
            // Update the locker details after selecting a locker
            setBoxNowDetails({
              boxNowLockerPostalCode: selected.boxnowLockerPostalCode,
              boxNowLockerAddressLine1: selected.boxnowLockerAddressLine1,
              boxNowLockerId: selected.boxnowLockerId,
            });
          },
        };

        // Dynamically load the Box Now script
        const script = document.createElement('script');
        script.src = 'https://widget-cdn.boxnow.gr/map-widget/client/v5.js';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        // Mark the widget as initialized
        setIsBoxNowWidgetInitialized(true);
      } else {
        // If the config is already loaded, simply refresh the widget
        window._bnclient_map_widget();
      }
    }
  };

  // Render the form
  return (
    <Container className='checkout-cont'>
      <h1 className='checkout-title'>Checkout</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
        </Form.Group>

        {/* Conditionally hide address fields if Box Now is selected */}
        {formData.shippingOption !== 'Box Now' && (
          <>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control type="text" name="addressLine1" value={formData.addressLine1} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control type="text" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="province">
                  <Form.Label>Province</Form.Label>
                  <Form.Control type="text" name="province" value={formData.province} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="zip">
                  <Form.Label>ZIP Code</Form.Label>
                  <Form.Control type="text" name="zip" value={formData.zip} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        <Form.Group controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Conditional Delivery Options */}
        <Form.Group>
          <Form.Label>Delivery Options</Form.Label>
          <Row>
            {formData.country === 'Greece' ? (
              <>
                <Col>
                  <Form.Check
                    type="radio"
                    name="shippingOption"
                    id="acs"
                    label={
                      <Figure>
                        <Figure.Image width={171} height={180} alt="ACS" src="/logos/acs_logo.png" />
                        <Figure.Caption>Delivery Price: €2.50</Figure.Caption>
                      </Figure>
                    }
                    value="ACS"
                    checked={formData.shippingOption === 'ACS'}
                    onChange={handleDeliveryOptionChange}
                    required
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    name="shippingOption"
                    id="boxNow"
                    label={
                      <Figure>
                        <Figure.Image width={171} height={180} alt="Box Now" src="/logos/box_now_logo.png" />
                        <Figure.Caption>Delivery Price: €1.50</Figure.Caption>
                      </Figure>
                    }
                    value="Box Now"
                    checked={formData.shippingOption === 'Box Now'}
                    onChange={handleDeliveryOptionChange}
                    required
                  />
                </Col>
              </>
            ) : (
              <Col>
                <Form.Check
                  type="radio"
                  name="shippingOption"
                  id="fedex"
                  label={
                    <Figure>
                      <Figure.Image width={171} height={180} alt="FedEx" src="/logos/fedex_logo.png" />
                      <Figure.Caption>Delivery Price: €10.00</Figure.Caption>
                    </Figure>
                  }
                  value="FedEx"
                  checked={formData.shippingOption === 'FedEx'}
                  onChange={handleDeliveryOptionChange}
                  required
                />
              </Col>
            )}
          </Row>
        </Form.Group>

        {/* Box Now Locker Selection */}
        {formData.shippingOption === 'Box Now' && (
          <>
            <Button className='boxnow-map-widget-button' size='lg' type='button' onClick={initBoxNowWidget}>
              Select Box Now Locker
            </Button>
            <div id="boxnowmap" style={{ width: '900px', height: '600px' }}></div>

            <Form.Group>
              <Form.Label>Selected Locker Details</Form.Label>
              <Form.Control
                type="text"
                value={`Address: ${boxNowDetails.boxNowLockerAddressLine1}, Postal Code: ${boxNowDetails.boxNowLockerPostalCode}, ID: ${boxNowDetails.boxNowLockerId}`}
                readOnly
              />
            </Form.Group>
          </>
        )}

        <Button className='order-now-button' size='lg' type="submit">
          Order Now
        </Button>
      </Form>
    </Container>
  );
}

export default Checkout;
