import React, { useState, useEffect } from "react";
import ItemGrid from "../../components/eshop/ItemGrid";
import { useParams } from "react-router-dom";
import axios from 'axios';

import "./ShopGrid.css";
import { getCategoryDisplayName } from "../../utils/eshop/categoryNameUtil";

function ShopGrid() {
    const { categoryUrl } = useParams();
    const [category, setCategory] = useState("");
    const [allCategories, setAllCategories] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.svkrobotics.com/api/items/get/categories`);
                setAllCategories(response.data); // assuming this returns an array of category names
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (allCategories.length > 0) {
            // Check if categoryUrl is in the fetched categories
            if (allCategories.includes(categoryUrl)) {
                setCategory(categoryUrl);
            } else {
                setCategory("all-items"); // Default category
            }
        }
    }, [allCategories, categoryUrl]);

    return (
        <>
            <h1 className="grid-title">{getCategoryDisplayName(category)}</h1>
            <ItemGrid category={category} />
        </>
    );
}

export default ShopGrid;
