import React from 'react';
import ReactMarkdown from 'react-markdown';
// Import for syntax highlighting (optional)
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Syntax theme

const MarkdownRenderer = ({ markdownText }) => {
  return (
    <ReactMarkdown
      children={markdownText}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              style={okaidia}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
    />
  );
};

export default MarkdownRenderer;
