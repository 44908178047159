import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { Link } from 'react-router-dom';

import "./Searchbar.css";

function Searchbar() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    axios.get('https://api.svkrobotics.com/api/items/get/all')
      .then(response => {
        setSearchItems(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowDropdown(true);
  };

  const handleDropdownItemClick = () => {
    setSearchQuery('');
    setShowDropdown(false);
  };

  const filteredItems = searchQuery ? searchItems?.items?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  return (
    <div className="searchbar-wrapper">
      <Form className="searchbar-form"> {/* Add a class for styling */}
        <InputGroup className="mb-3 searchbar-input">
          <InputGroup.Text id="basic-addon1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
          </InputGroup.Text>
          <Form.Control
            type='text'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchChange}
            className='search-input'
          />
        </InputGroup>
        {showDropdown && (
          <ul className="search-dropdown">
            {filteredItems.map(item => (
              <li key={item.id}>
                <Link to={`/eshop/item/${item.id}`} className="dropdown-link" onClick={handleDropdownItemClick}>{item.name}</Link>
              </li>
            ))}
          </ul>
        )}
      </Form>
    </div>
  );
}

export default Searchbar;
