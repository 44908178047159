import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../actions/actions";
import { Row, Col, Button, Container } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import ItemImageCarousel from "../../components/eshop/ItemImageCarousel";
import QuantityControl from "../../components/eshop/QuantityControl";
import Breadcrumbs from "../../components/eshop/Breadcrumbs";
import AddToCartModal from "../../components/eshop/AddToCartModal";
import MarkdownRenderer from "../../components/eshop/MarkdownRenderer";
import { getCategoryDisplayName } from "../../utils/eshop/categoryNameUtil";

import "./Item.css";

function Item({ addToCart }) {
    const { itemId, slug } = useParams();  // Get both itemId and slug from the URL
    const [itemData, setItemData] = useState(undefined);
    const [quantity, setQuantity] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItemData = async () => {
            try {
                const response = await axios.get(`https://api.svkrobotics.com/api/items/${itemId}`);
                const item = response.data.item;

                setItemData(item);
                document.title = item.name;

                // If the slug in the URL doesn't match the slug from the database, update the URL
                if (slug !== item.slug) {
                    navigate(`/eshop/item/${itemId}/${item.slug}`, { replace: true });
                }

            } catch (error) {
                console.error('Error fetching item data:', error);
            }
        };

        fetchItemData();
    }, [itemId, slug, navigate]);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const handleAddToCart = () => {
        const uuid = uuidv4();
        addToCart(itemData, quantity, uuid);
        setShowAlert(true); // Show alert when item is added to cart
    };

    if (itemData === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div className="item-page">

            {/* Added to cart ALERT container */}
            <Container>
                <Col>
                    {/* Render the alert component */}
                    <AddToCartModal showAlert={showAlert} setShowAlert={setShowAlert} />
                </Col>
            </Container>

            {/* Container for image carousel and add to cart buttons and other buttons */}
            <Row className="item-sale-cont">
                <Breadcrumbs type="item" data={itemData} />
                <Col>
                    <Container>
                        <ItemImageCarousel data={itemData} />
                    </Container>
                </Col>

                <Col>
                    <Container>
                        <Col>
                            <h1>{itemData?.name}</h1>
                            <hr />
                            <p>{itemData?.description}</p>
                            <p className="item-categories">Category: <a href={`/eshop/category/${itemData?.category}`}>{getCategoryDisplayName(itemData?.category)}</a></p>
                            {/* <h3 className="item-price">Price: Inquire for price details</h3> */}
                            <Row className="buy-row" md={2}>
                                <Col>
                                    <QuantityControl onQuantityChange={handleQuantityChange} />
                                </Col>
                                <Col className="buy-button-col">
                                    <Button size="lg" className="buy-button">
                                        Orders Available Soon
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Col>
            </Row>

            {/* Description Full Container, renders if description is not null*/}
            {itemData?.description_full && (
            <Container className="desc-cont">
                <h2>Description </h2>
                <MarkdownRenderer markdownText={itemData?.description_full} />
            </Container>
            )}

            {/* Specifications Container, renders if specifications are not null */}
            {itemData?.specifications && (
            <div className="specs-cont">
                <Container>
                    <h2>Specifications</h2>
                    <MarkdownRenderer markdownText={itemData?.specifications} />
                </Container>
            </div>
            )}
            
        </div>
    );
}

export default connect(null, { addToCart })(Item);
